@use 'sass:map';
@use '@angular/material' as mat;

$max-width: 900px;
$max-width-webapp: 500px;

// our base is 4px see why here
// https://webflow.com/blog/why-were-using-a-4-point-grid-in-webflow
$base: 1rem * 0.25;

// **** TYPOGRAPHY
@use 'fontsource-spartan/700-normal' as N_700;
@use 'fontsource-spartan/400-normal' as N_400;

$font-family: 'Roboto, "Helvetica Neue", sans-serif';
$display-font: 'Spartan, Avenir, sans-serif';

//@function mat-typography-level(
//  $font-size,
//  $line-height: $font-size,
//  $font-weight: 400,
//  $font-family: null,
//  $letter-spacing: normal) {

// we are roughly using a major third scale just tweaked to align to a 4dp grid
// resources
// https://material.io/design/layout/spacing-methods.html#baseline-grid
// https://webflow.com/blog/why-were-using-a-4-point-grid-in-webflow

// Not using a real baseline as its very hard
// see: https://builttoadapt.io/8-point-grid-vertical-rhythm-90d05ad95032
// Real baseline generator
// see: https://nowodzinski.pl/syncope/

// everything must be divisible by 4 so it lines up on the baseline
$line-height: 1.5;
$alternative-line-height: 1.4;

$display-4: mat.define-typography-level(
    60px,
    $alternative-line-height,
    700,
    $display-font
);
$display-3: mat.define-typography-level(48px, $line-height, 400, $display-font);
$display-2: mat.define-typography-level(
    40px,
    $line-height,
    700,
    $display-font,
    0.05em
);
$display-1: mat.define-typography-level(
    40px,
    $line-height,
    400,
    $display-font,
    0.05em
);

$display-0: mat.define-typography-level(
    24px,
    48px,
    700,
    $display-font,
    -0.05em
);

// h1 = 32/48
$headline: mat.define-typography-level(32px, $line-height, 700, $font-family, -1.5px);

// h2 = 24/36
$title: mat.define-typography-level(24px, $line-height, 400);

// h3 = 20/28
$subheading-2: mat.define-typography-level(20px, $alternative-line-height, 400);

// h4 = 16/24
$subheading-1: mat.define-typography-level(16px, $line-height, 700);

// body-2 = 16/28
$body-2: mat.define-typography-level(16px, 1.75, 400);
// body-1 = 16/24
$body-1: mat.define-typography-level(16px, $line-height, 400);
$caption: mat.define-typography-level(14px, 2, 400);
$button: mat.define-typography-level(16px, $line-height, 500);

// Line-height must be unit-less fraction of the font-size.
$input: mat.define-typography-level(16px, 1.125, 400);

/* to apply any of our fonts to text on page, use this syntax
p {
    @include mat-typography-level($typography-carabiner, [WHATEVERFONT_YOU_WANT, e.g. body-2, no $ sign]);
}
*/

$typography-carabiner: mat.define-typography-config(
    $font-family,
    $display-4, // angular 15 now headline 1
    $display-3, // angular 15 now headline 2
    $display-2, // angular 15 now headline 3
    $display-1, // angular 15 now headline 4
    $headline, // angular 15 now headline 5
    $title, // angular 15 now headline 6
    $subheading-2, // angular 15 now subtitle-1
    $subheading-1, // angular 15 now subtitle-2
    $body-1, // angular 15 swapped body 1 and body 2
    $body-2,
    $caption,
    $button,
);

$typography-carabiner-legacy: mat.define-legacy-typography-config(
    $font-family,
    $display-4,
    $display-3,
    $display-2,
    $display-1,
    $headline,
    $title,
    $subheading-2,
    $subheading-1,
    $body-2,
    $body-1,
    $caption,
    $button,
    $input
);

// This is a util extracted from ang material to easily output font info including line height even out of fonts that aren't part of the material set, e.g. display-0 as used in our designs

@function get-font-family($level) {
  $font-family: map-get($level, font-family);
  @return if(type-of($font-family) == string, unquote($font-family), $font-family);
}

//sample: @include typography-level-to-styles($display-0)

@mixin typography-level-to-styles($level) {
  $font-size: map-get($level, font-size);
  $font-weight: map-get($level, font-weight);
  $line-height: map-get($level, line-height);
  $font-family: get-font-family($level);
  @include mat.font-shorthand($font-size, $font-weight, $line-height, $font-family);
  letter-spacing: map-get($level, letter-spacing);
}

// Dark theme 100, 500, 700 are the default values used by mat-palette
// See a default pallet to see all the options it includes

// primary
$dark-primary: #80ecff;
$dark-primary-contrast: #17155b;

// light variant
$dark-primary-lv: #affffa;
$dark-primary-lv-contrast: #17155b;

// dark variant
$dark-primary-dv: #3d1ff5;
$dark-primary-dv-contrast: white;

$carabiner-dark-primary: (
  100: $dark-primary-lv,
  500: $dark-primary,
  700: $dark-primary-dv,
  contrast: (
    100: $dark-primary-lv-contrast,
    500: $dark-primary-contrast,
    700: $dark-primary-dv-contrast,
  ),
);

// primary
$dark-accent: #ffcf02;
$dark-accent-contrast: #17155b;

// light variant
$dark-accent-lv: #ffdc42;
$dark-accent-lv-contrast: #17155b;

// dark variant
$dark-accent-dv: #ffbb00;
$dark-accent-dv-contrast: #09061e;

$carabiner-dark-accent: (
  100: $dark-accent-lv,
  500: $dark-accent,
  700: $dark-accent-dv,
  contrast: (
    100: $dark-accent-lv-contrast,
    500: $dark-accent-contrast,
    700: $dark-accent-contrast,
  ),
);

$dark-link: $dark-primary;
$dark-link-visited: #c968ff;

// mat-palette takes a colour scheme and by default extracts these colours, text default refers back to 500
// mat-palette($base-palette, $default: 500, $lighter: 100, $darker: 700, $text: $default)
// you can extract other colours by overriding the defaults i.e:
// mat-palette ($mat-deep-orange, 700, A100, A700, 500)
// contrast colours will be chosen from lighter and darker contrasts will be selected with the same number

$dark-primary-palette: mat.define-palette($carabiner-dark-primary);
$dark-accent-palette: mat.define-palette($carabiner-dark-accent);

$dark-warn-palette: mat.define-palette(mat.$deep-orange-palette);
//warn: $dark-warn,
$dark-theme: mat.define-dark-theme((
  color: (
    primary: $dark-primary-palette,
    accent: $dark-accent-palette,
    warn: $dark-warn-palette,
  ),
));

$dark-foreground-palette: map-get($dark-theme, foreground);
$dark-background-palette: map-get($dark-theme, background);

// primary
$light-primary: #3d1ff5;
$light-primary-contrast: white;

// light variant
$light-primary-lv: #01cff4;
$light-primary-lv-contrast: #17155b;

// light dark variant
$light-primary-dv: #17155b;
$light-primary-dv-contrast: white;

$carabiner-light-primary: ((
  100: $light-primary-lv,
  500: $light-primary,
  700: $light-primary-dv,
  contrast: (
    100: $light-primary-lv-contrast,
    500: $light-primary-contrast,
    700: $light-primary-dv-contrast,
  ),
  typography: $typography-carabiner
));

// primary
$light-accent: #01cff4;
$light-accent-contrast: #17155b;

// light variant
$light-accent-lv: #80ecff;
$light-accent-lv-contrast: #17155b;

// dark variant
$light-accent-dv: #00c1ec;
$light-accent-dv-contrast: #17155b;

$carabiner-light-accent: (
  100: $light-accent-lv,
  500: $light-accent,
  700: $light-accent-dv,
  contrast: (
    100: $light-accent-lv-contrast,
    500: $light-accent-contrast,
    700: $light-primary-dv-contrast,
  ),
);

$light-link: $light-primary;
$light-link-visited: #850acd;

// Light theme
$light-primary-palette: mat.define-palette($carabiner-light-primary);
$light-accent-palette: mat.define-palette($carabiner-light-accent);

$light-theme: mat.define-light-theme((
  color: (
    primary: $light-primary-palette,
    accent: $light-accent-palette,
  ),
  typography: $typography-carabiner-legacy
));

$light-warn-palette: map-get($light-theme, warn);
$light-foreground-palette: map-get($light-theme, foreground);
$light-background-palette: map-get($light-theme, background);

//hero colours
$brand-yellow: #ffcf02;
$brand-yellow-contrast: #17155B;

// mat-color($dark-primary, hue = default, opacity = null);
// options default, lighter, darker, text, default-contrast, lighter-contrast, darker-contrast
// use darker-contrast if background is default

