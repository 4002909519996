@use 'styles/_core-theme.scss' as core;
@use 'styles/variables' as var;
@use '@angular/material' as mat;

a {
  color: var.$light-link
}

a:visited {
  color: var.$light-link-visited;
}

.mat-button-toggle.mat-button-toggle-checked {
  background-color: var.$light-primary;
  color: var.$light-primary-contrast;
}


.dark-theme {
  a {
    color: var.$dark-link
  }
  a:visited {
    color: var.$dark-link-visited
  }
  .mat-button-toggle.mat-button-toggle-checked {
    background-color: var.$dark-primary;
    color: var.$dark-primary-contrast;
  }
}

html, body {
  width: 100%;
  height: 100%;
}
