@forward './variables';
@use "./variables" as var;
@use '@angular/material' as mat;
@use 'material-design-icons/iconfont/material-icons';
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// have pre code-blocks wrap useful for development
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**

@include mat.core();

//@include mat.all-component-typographies(var.$typography-carabiner);

// Include the core them only every once
@include mat.core-theme(var.$light-theme);

// can import css for just the components we ar using
@include mat.all-component-typographies(var.$light-theme);
@include mat.all-component-themes(var.$light-theme);

//// Include the dark color styles inside of a block with a CSS class. You can make this
//// CSS class whatever you want. In this example, any component inside of an element with
//// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.dark-theme {
  // use core-color as we do not want to re-include all the core themes
  @include mat.core-color(var.$dark-theme);

  // can import css for just the components we ar using
  @include mat.all-component-colors(var.$dark-theme);
}

.primary-color-text {
  color: var.$light-primary
}

.dark-theme {
  .primary-color-text {
    color: var.$dark-primary
  }
}
